import React, {useRef} from 'react';
import './HoloCard.css';


const HoloCard = (props)=>{
    const {image, alt} = props;
    
    const holoCard = useRef(null);

    const resetTransform = (el, perspective = 800) =>
  (el.style.transform = `translate3d(0%, 0%, -${800 /
    2}px) rotateX(0deg) rotateY(0deg)`);

    const onCardMove = ev => onMove(ev, ev.target);
    const onHover = ev => ev.target.addEventListener("mousemove", onCardMove);
    const onOut = ev => {
        resetTransform(ev.target, 800); // reset card
        ev.target.removeEventListener("mousemove", onCardMove);
      };
    const onMove = (ev, el) => {
        const { pageX, pageY } = ev;
        const { offsetWidth, offsetHeight } = el;
        const { left, top } = el.getBoundingClientRect();
      
        const cardX = left + offsetWidth / 2;
        const cardY = top + offsetHeight / 2;
      
        const angle = 25;
        const rotX = (cardY - pageY) / angle;
        const rotY = (cardX - pageX) / -angle;
        
        el.style.transform = `translate3d(0%, 0%, 0) rotateX(${rotX}deg) rotateY(${rotY}deg)`;
      };
      

    return(
        <div className="holo-wrapper">
            {/* <img className="holo-card" src={image} alt={alt} /> */}
            <div ref={holoCard} onMouseOver={onHover} onMouseOut={onOut} className="holo-card"  style={{ backgroundImage: `url(${image})` }} title={alt}></div>
        </div>
    )
}

export default HoloCard;