import React from "react";
import { useAccount } from 'wagmi'

import Container from 'react-bootstrap/Container'
import History from "./../components/BagToken/History";
import Dispersal from "../components/BagToken/Dispersal";

import ConnectHelper from "./../components/BagToken/ConnectHelper";
import MessageSigner from "../components/BagToken/MessageSigner";


const BagToken = (props)=> {
    const { address } = useAccount()

    return (
      <Container>
            {address ? ( 
                <>
                <MessageSigner/>           
                <History address={address} />
                <Dispersal address={address}/>
                </>
            ):(
                <ConnectHelper />
            )}
        </Container>
    )
}



export default BagToken;