import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import img1 from './../../assets/images/img-collection/KANDINSKYPEP.jpeg'
import img2 from './../../assets/images/img-collection/SHITCOINCARD.jpg'
import img3 from './../../assets/images/img-collection/EAZYP.png'
import img4 from './../../assets/images/img-collection/CULTOFKEK.png'
import img5 from './../../assets/images/img-collection/PLATINUMPEPE.gif'
import img6 from './../../assets/images/img-collection/CHEECHPEPE.jpg'
import img7 from './../../assets/images/img-collection/PEPEGOAT.jpg'
import img9 from './../../assets/images/img-collection/GOXPEPE.jpg'
import img10 from './../../assets/images/img-collection/RAREPEPE.jpg'

import avt1 from './../../assets/images/avatar/avt-1.jpg'
import avt2 from './../../assets/images/avatar/avt-2.jpg'
import avt3 from './../../assets/images/avatar/avt-3.jpg'
import avt4 from './../../assets/images/avatar/avt-4.jpg'
import avt5 from './../../assets/images/avatar/avt-5.jpg'
import avt6 from './../../assets/images/avatar/avt-6.jpg'
import avt7 from './../../assets/images/avatar/avt-7.jpg'
import avt8 from './../../assets/images/avatar/avt-8.jpg'
import avt9 from './../../assets/images/avatar/avt-9.jpg'
import avt10 from './../../assets/images/avatar/avt-10.jpg'
import avt11 from './../../assets/images/avatar/avt-11.jpg'
import avt12 from './../../assets/images/avatar/avt-12.jpg'
import avt13 from './../../assets/images/avatar/avt-13.jpg'
import avt14 from './../../assets/images/avatar/avt-14.jpg'
import Slider from "./Slider";

import './VaultDetails.css';


const dataSlider = [
    {
        title: "The RarePepeBag Vault",
        description: "The Rarepepebag's <a href='https://fractional.art/vaults/bagholder' target='_blank'>$bagholder vault</a> collects liquidity pool trading fees, those fees will be used to further accrue rarepepes for this vault to own. Trading enduces fee collection and chart progress in either directions helps to form more value into the underlying asset (the vault's collection)",
        p1:"Token Holders are free to add more assets to the vault, to assist the $bagholder community as a whole, which is encouraged but it is not required.",
        p2:"Initial Holders of the <a href='https://etherscan.io/token/0x70881d5c8a5950ceedf1f1b4b5d4105718642548' target='_blank'>bagholder</a> token were mostly RAREPEPE OGs whom contributed part of their own loved collection into the vault in return for token supply. Since the project started over 450 cards have been bought for $bag by community members who wanted their stake in the vault, and there are many more for us to collect.",
        p3: "RAREPEPEs is the original \"NFT\" community, born in Bitcoin's op_returns with a history from 2016. While the Vault may contain other assets of interest and value our main concern is adding to our RAREPEPEs, and tracking us through the legendary RAREPEPEs holder leaderboard.",
        img1: img1,
        img2: img2,
        img3: img3,
        img4: img4,
        img5: img5,
        img6: img6,
        img7: img7,
        img8: img10,
        img9: img9,
        avt1: avt1,
        avt2: avt2,
        avt3: avt3,
        avt4: avt4,
        avt5: avt5,
        avt6: avt6,
        avt7: avt7,
        avt8: avt8,
        avt9: avt9,
        avt10: avt10,
        avt11: avt11,
        avt12: avt12,
        avt13: avt13,
        avt14: avt14
    }];

const VaultDetails = (props) => {
  return (
    <section className="vault-details">
        <Slider data={dataSlider} />
    </section>
  );
};

export default VaultDetails;
