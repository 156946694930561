import React , { useRef, useState  } from 'react';
import { Link, useLocation } from 'react-router-dom' 

import { useAccount } from 'wagmi'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { ConnectButton } from '@rainbow-me/rainbowkit';

import menus from '../pages/menu';
import logo from '../assets/images/pepebag_logo_trans.png'

import './Header.css'
import routes from '../pages/router';


const Header = (props) => {
    const location = useLocation();
    const { address, isConnecting, isDisconnected } = useAccount()
    const [activeIndex, setActiveIndex] = useState(null);

   // const { pathname } = useLocation();
    const menuLeft = useRef(null)
    const btnToggle = useRef(null)
    const menuToggle = () => {
        menuLeft.current.classList.toggle('active');
        btnToggle.current.classList.toggle('active');
    }

    const handleOnClick = index => {
        setActiveIndex(index);
    };
    
    return (
        <header id="header_main">
            {/* <div className="container">
                <div className="row">
                    <div className="col-md-12">

                        <div className="flex flex-justify-between">
                            <div id="site-logo" className="clearfix">
                                <div id="site-logo-inner">
                                    <Link to="/" rel="home" className="main-logo">
                                        <img id="logo_header" className='logo-dark' src={logo} srcSet={logo} alt="RarePepeBag" />
                                       
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <nav id="main-nav" className="main-nav">
                            <ul id="menu-primary-menu" className="menu">
                                {
                                    menus.map((data,index) => (
                                        <li key={index} onClick={()=> handleOnClick(index)} className={`menu-item ${activeIndex === index ? 'active' : ''} ` }   >
                                            <Link to={data.links}>{data.name}</Link>
                                        </li>
                                    ))
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div> */}
            <Navbar expand="lg"  bg="success" data-bs-theme="dark" >
                <Container>
                    <Navbar.Brand>
                        <Link to="/">
                        <img id="logo_header" className='logo' src={logo} srcSet={logo} alt="RarePepeBag" />
                        <span className="logo-text">RarePepeBag</span>   
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse  className="justify-content-end" >
                        <Nav className="nav-bar-custom">
          
                            { menus.map((data,index) => (
                                <Nav.Link key={index}><Link  to={data.links}>{data.name}</Link></Nav.Link>
                            ))}
                        </Nav>
                    </Navbar.Collapse>
                    {/* {!address && ( */}
                    
                    { routes.filter((route)=> route.path===location.pathname)[0].connect && (
                        <ConnectButton chainStatus="none" showBalance={false}/>
                    )}
                    {/* )} */}

                </Container>
            </Navbar>
        </header>
    )
}

export default Header;