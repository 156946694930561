import React , { useState , useEffect } from 'react';
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import logo from '../assets/images/pepebag_logo_trans.png'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import './Footer.css'

import gentleman from './../assets/images/gentleman.gif'
const Footer = () => {
    const [dataSocial] = useState([

        {   link:'https://t.me/RAREPEPEBAG',
            icon: 'fab fa-telegram'
        },
        {   link:'https://twitter.com/rarepepebag',
            icon: 'fab fa-twitter'
        }
    ])

    const [dataLinkMarket] = useState([
      {
          title: 'Trading on DexTools',
          link: 'https://www.dextools.io/app/en/ether/pair-explorer/0x373cc766e151688265ce9f79cc3d752886ed5c54'
       },{
        title: '$BAG Contract on Etherscan',
        link: 'https://etherscan.io/token/0x70881d5c8a5950ceedf1f1b4b5d4105718642548'
     },{
        title: 'BagHolder Vault\'s Bitcoin wallet on Xchain.io',
        link: 'https://xchain.io/address/1KdX4ePLx8hmXcNvz2tD1uKgp5cHMyFDB2'
     }




    ])

    const [dataSupport] = useState([
        {
            title: '$Bag Telegram Room',
            link: 'https://t.me/RarePepeBag'
        },
        {
            title: '$Bag Discord Server',
            link: 'https://discord.gg/KByDnFfT'
        },
        {
            title: 'RAREPEPEs Telegram Room',
            link: 'https://t.me/+RHJ6MDMME545MDk5'
        },
        {
            title: 'Twitter $BAG Info',
            link: 'https://twitter.com/RarePepeBag'
        },
        {
            title:"What are RarePepes, an article by Martin Lukas Ostachowski",
            link:"https://mlo.art/news/blog-posts/a-brief-history-of-rare-pepes/"
        },
        {
            title:"RarePepe Directory",
            link:"https://rarepepedirectory.com/"
        },
        {
            title:"RarePepeWallet",
            link:"https://rarepepewallet.com/"
        },
        {
            title:"Pepe.Wtf",
            link:"https://pepe.wtf/"
        },
        {
            title:"Rarepepes.com",
            link:"https://rarepepes.com/"
        }

    ])

    const [dataRecent] = useState([
        {
          

            title: 'Page 1 on Leaderboard, position 16',
            time: 'Nov 1 2023',
            link: "https://etherscan.io/token/0x70881d5c8a5950ceedf1f1b4b5d4105718642548",
            linkLabel: "RarePepeBag"
        },{
          

          title: 'From Page 20 to page 5 in LeaderBoard',
          time: 'July 9 2023',
          link: "https://etherscan.io/token/0x70881d5c8a5950ceedf1f1b4b5d4105718642548",
          linkLabel: "RarePepeBag"
      },{
          

        title: 'Bagholder Vault is Born, and 100,000,000 fractions created.',
        time: 'May 14 2023',
        link: "https://etherscan.io/token/0x70881d5c8a5950ceedf1f1b4b5d4105718642548",
        linkLabel: "RarePepeBag"
    },
      
    ])

    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    };

    useEffect(() => {
      const toggleVisibility = () => {
        if (window.pageYOffset > 500) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      window.addEventListener("scroll", toggleVisibility);

      return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);
  return (
            <div>
            <footer className="clearfix bg-style bg-body-success footer">
                <Navbar expand="lg"  bg="success" data-bs-theme="dark" >
                    <Container>
                        <Row>
                            <Col>
                                <Navbar.Brand>
                                    <Link to="/">
                                        <img id="logo_header" className='logo' src={logo} srcSet={logo} alt="RarePepeBag" />
                                        <span className="logo-text">RarePepeBag</span>  
                                    </Link> 
                                </Navbar.Brand>
                                <p className="sub-widget-logo">
                                The Bagholder Vault is fractionalised by smart contract, resulting in 100,000,000 ERC20 tokens ($BAG) which are uniswap tradable.
                                Fees accrued from the trading of $BAG will be used to purchase more rarepepes to enter the vault.<br/><br/> <a className="sc-button primary" href="mailto:bag@rarepepebag.com">bag@rarepepebag.com</a>
                                </p>
                            </Col>
                            <Col className='goof'>
                            <img src={gentleman} alt="Gentleman BagHolder By Hiroon" title="Gentleman BagHolder By Hiroon" />
                            <p><small>Top Telegram sticker designer and animator '<a rel="noreferrer" target="_blank" href="https://www.instagram.com/hiroon1">Hiroon</a>' supplied these great motion graphics.</small></p>
                            </Col>
                            <Col>

                            <h5 className="title-widget">Contracts/Markets</h5>
                                <ul>
                                    {
                                        dataLinkMarket.map((item,index)=> (
                                            <li key={index}><a rel="noreferrer" target="_blank" href={item.link}>{item.title}</a></li>
                                        ))
                                    }

                                </ul>
                            
                            </Col>
                            <Col>
                            <h5 className="title-widget">Resources</h5>
                                <ul>
                                    {
                                        dataSupport.map((item,index)=> (
                                            <li key={index}><a target="_blank" href={item.link}>{item.title}</a></li>
                                        ))
                                    }
                                </ul>
                            </Col>
                            <Col>
                            <h5 className="title-widget">$BAG News</h5>
                                <ul className="post-new">
                                    {
                                        dataRecent.map((item,index)=> (
                                            <li key={index}>
                                                <div className="post-img">
                                                <a target="_blank" rel="noreferrer" href={item.link}>
                                                    </a>
                                                </div>
                                                <div className="post-content">
                                                <small>{item.time}</small>  
                                                    <p className="title">{item.title}</p>
                                                   
                                                    
                                                </div>
                                            </li>
                                        ))
                                    }
                                </ul>
                                </Col>
                        </Row>
                    
                   
                
                </Container>
                </Navbar>
            </footer>
            
            {
                isVisible &&
                <Link onClick={scrollToTop}  to='#' id="scroll-top"></Link>
            }
            </div>
  );
};

export default Footer;
