import React from "react";
import Home from "./Home";
import Vault from "./Vault";
import VaultCompare from "./VaultCompare";
import Nakahoneypot from "./Nakahoneypot"
import Rarepepe from "./RarePepe";
import BagToken from "./BagToken";


const routes = [
    { path: '/', component: <Home />,connect:false},
    { path: '/vault', component: <Vault />,connect:false},
    { path: '/vault/compare', component: <VaultCompare />,connect:false},
    { path: '/rarepepe', component: <Rarepepe />,connect:false},
    { path: '/honeypot', component: <Nakahoneypot />,connect:false},
    { path: '/bag-token', component: <BagToken />,connect:true}
]

export default routes;
