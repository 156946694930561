import React,{useEffect} from "react";
import {connect} from 'react-redux'
import { loadHoneypot } from "../redux/interactions"
import { honeypotSelector } from "../redux/selectors";
import Honeypot from "../components/Nakahoneypot/HoneyPot";
import Honeypotcollection from "../components/Nakahoneypot/Honeypotcollection";

import HoneypotViewer from "../components/HoneypotViewer/HoneypotViewer";


const Nakahoneypot = (props)=> {
    const {honeypot, dispatch} = props;
    useEffect(()=>{
        if(!honeypot){
          loadHoneypot(dispatch);
        }
      })

    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <Honeypot></Honeypot>
                    
                    <Honeypotcollection></Honeypotcollection>
                    
                    { honeypot && (
                        <HoneypotViewer data={honeypot}/>
                    )}
                </div>
            </div>
        </div>
    )
}


function mapStateToProps(state){
	return {
        honeypot: honeypotSelector(state)
	}
}

export default connect(mapStateToProps)(Nakahoneypot);
