import React , {useState} from 'react';

import { Dropdown } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

import imgScarce from './../../assets/images/markets/scarce.png'
import imgOpensea from './../../assets/images/markets/opensea.png'
import imgDankset from './../../assets/images/markets/dankset.png'
import imgWTF from './../../assets/images/markets/pepewtf.png'
import imgXchain from './../../assets/images/markets/xchain.png'

import './VaultViewer.css'
import brokenImage from './../../assets/images/no_image_yet.png'
import { Link } from 'react-router-dom';

const VaultViewer = props => {
    const data = props.data;

    const [visible , setVisible] = useState(50);
    const [selection , setSelection] = useState("myRarePepes");

    const [set , setSort] = useState("Card No");

    const [filter , setFilter] = useState("All");

    const showMoreItems = () => {
        setVisible((prevValue) => prevValue + 50);
    }

    const signatures = {
        JVBERi0: 'application/pdf',
        R0lGODdh: 'image/gif',
        R0lGODlh: 'image/gif',
        iVBORw0KGgo: 'image/png',
        '/9j/': 'image/jpg',
    };
    
    const getMimeType = (base64)=>{
        for(const sign in signatures)if(base64.startsWith(sign))return signatures[sign];
    };
    const regexMP4 = /\.mp4/i;

    const sorting = (a,b)=>{

        switch (set) {
            case "Card No":
                return a.card - b.card;
            case "Card No Reverse":
                return b.card - a.card;
            case "OG Rarity":
                return a.quantity - b.quantity;
            case "OG Rarity Reverse":
                return b.quantity - a.quantity;
            case "Technical Rarity":
                return (a.quantity-a.burned) - (b.quantity-b.burned);
            case "Technical Rarity Reverse":
                return (b.quantity-b.burned) - (a.quantity-a.burned);
            case "Card Name":
                const nameA1 = a.title.toUpperCase(); // ignore upper and lowercase
                const nameB1 = b.title.toUpperCase(); // ignore upper and lowercase
                if (nameA1 < nameB1) {
                  return -1;
                }
                if (nameA1 > nameB1) {
                  return 1;
                }
                return 0;
            case "Card Name Reverse":
                const nameA = a.title.toUpperCase(); // ignore upper and lowercase
                const nameB = b.title.toUpperCase(); // ignore upper and lowercase
                if (nameB < nameA) {
                  return -1;
                }
                if (nameB > nameA) {
                  return 1;
                }
                return 0;

            default:
                return a.card - b.card;
        }
    }


    const sortingLabel = ()=>{

        switch (set) {
            case "Card No":
                return "Card No"
            case "Card No Reverse":
                return "Card No Rv"
            case "OG Rarity":
                return "OG Rarirty"
            case "OG Rarity Reverse":
                return "OG Rarirty Rv"
            case "Technical Rarity":
                return "Tech Rarirty"
            case "Technical Rarity Reverse":
                return "Tech Rarirty Rv";
            case "Card Name":
                return "Alpha";
            case "Card Name Reverse":
                return "Alpha Rv";
            default:
                return "Card No";
        }

    

       
    }

    const filtering = (asset)=>{
        if(filter==="All"){
            return true;
        } else {
            return asset.series === filter;
        }    
    }

    const selectionLabel = ()=>{
        switch (selection) {
            case 'myRarePepes':
                return "VAULTED RPs"      
            case 'notGotRarePepes':
                return "MISSING RPs"
            case 'myNotRarePepes':
                return "VAULTED Other"
            case 'rarePepes':
                return "ALL RPs"
            default:
        }
    }

    const filterLabel = ()=>{
        if(filter==="All"){
            return "All";
        }else {
            return "Series "+filter;
        }
    }
   
  return (
    <section className="vault-viewer">
        <div className="">
            <p className="opening-para">Use this page to view the RAREPEPEs (and other collections) cards that the bag does or does not have, to see which cards you have that the bag doesnt (for donation or $bag offers) please use our <Link to="/vault/compare">vault comparison tool</Link></p>
            <Navbar expand="lg"  bg="success" data-bs-theme="dark" className="rounded control-bar">
                <Navbar.Brand><label className="item-count-label style style-2">Items: {data[selection].filter(filtering).length} </label>
                    </Navbar.Brand>
                        <Nav>
                        <Dropdown>
                            <Dropdown.Toggle variant="success">
                                <span>Series {filterLabel()}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={()=>setFilter("All")}>
                                <span>See All</span>
                            </Dropdown.Item>

                            {[...Array(36)].map((x, i) =>
                                <Dropdown.Item href="#" onClick={()=>setFilter(i+1)}>
                                <span>Series {i+1}</span>
                                </Dropdown.Item>
                            )}

                            </Dropdown.Menu>
                        </Dropdown>

                        <Dropdown>
                            <Dropdown.Toggle className="btn-selector nolink" id="dropdown-basic" variant="success">
                                <span>Sort by {sortingLabel()}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={()=>setSort("Card No")}>
                                <span>Card No</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={()=>setSort("Card No Reverse")}>
                                <span>Card No Reverse</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={()=>setSort("OG Rarity")}>
                                <span>OG Rarity</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#"  onClick={()=>setSort("OG Rarity Reverse")}>
                                <span>OG Rarity Reverse</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={()=>setSort("Technical Rarity")}>
                                <span>Techincal Rarity</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={()=>setSort("Technical Rarity Reverse")}>
                                <span>Technical Rarity Reverse</span>
                            </Dropdown.Item>

                            <Dropdown.Item href="#"onClick={()=>setSort("Card Name")}>
                                <span>Card Name</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#"onClick={()=>setSort("Card Name Reverse")}>
                                <span>Card Name Reverse</span>
                            </Dropdown.Item>

  

                            </Dropdown.Menu>
                        </Dropdown> 

                        <Dropdown>
                            <Dropdown.Toggle className="btn-selector nolink" id="dropdown-basic" variant="success">
                                <span>Showing {selectionLabel()}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={()=>setSelection("myRarePepes")}>
                                <span>Vaulted RPs</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={()=>setSelection("myNotRarePepes")}>
                                <span>Vaulted Other</span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={()=>setSelection("rarePepes")}>
                                <span>All RarePepes </span>
                            </Dropdown.Item>
                            <Dropdown.Item href="#" onClick={()=>setSelection("notGotRarePepes")}>
                                <span>UnVaulted RPs</span>
                            </Dropdown.Item>
                           

                            </Dropdown.Menu>
                        </Dropdown>
                    </Nav>
                </Navbar>
                        
            </div>
                
                <Row>
                {
                    data[selection].filter(filtering).sort(sorting).slice(0,visible).map((item,index)=> (
                        <Col key={index} className="col-xl-3 col-lg-3 col-md-3">
                            <div className="sc-product-item style-5">
                                <div className="product-img">
                                    {item.collections.indexOf('bitcoin-stamps')===-1 ?(
                                        <div>
                                        { (item.img_url).match(regexMP4) ? ( 
                                            
                                            <video width="100%" controls>
                                                    <source src={item.img_url} type="video/mp4" />
                                            </video>
                                         ):(
                                            (item.img_url !=='') ?(
                                            <img src={item.img_url} alt="RarePepeBag!" />
                                            ):(
                                                <img src={brokenImage} alt="No Image yet" /> 
                                            )
                                         )}
                                        </div>
    
                                    ):(
                                        // <img src={item.img_url} alt="RarePepeBag" />
                                        <img className="bitcoin-stamp" 
                                        src={'data:'+getMimeType(item.description.replace(/^STAMP:/i,''))+';charset=utf-8;base64,'+item.description.replace(/^STAMP:/i,'')} alt={selection} />
                                    )}
                                    
                                    {/* <Link to="/connect-wallet"
                                        className="sc-button style letter"><span>Place Bid</span></Link> */}
                                    
                                     {/*<div className="avatar-box">
                                        {
                                            item.avtList.map((item,index) => (
                                                <img key={index} src={item.img1} alt="RarePepeBag" />
                                            ))
                                        } 
                                    </div>*/}
                                </div>
                                <div className="product-content">
                                <label>{item.collections.join(', ').replace('rare-pepe','RAREPEPEs').replace('fake-rare','FAKERARES').replace('fake-commons','FAKECOMMONS')}</label>
                                    <h5 className="title">{item.title_alt?item.title_alt:item.title} &nbsp;&nbsp; {item.collections.indexOf('rare-pepe') !==-1?`S${item.series}-C${item.order}`:''}  </h5>
                                    {/* <p><pre>{JSON.stringify(item,null,2)}</pre></p> */}
                                    <p>💎 {Number(item.holding) === 0?'0': Number(item.holding) <= 0.001  ?'Dust': Number(item.holding).toLocaleString('en','us')}{item.collections.indexOf('rare-pepe') !==-1?`/${Number(item.quantity).toLocaleString('en','us') } (🔥${item.burned})`:''}</p>
                                    {/* <div className="product-author flex">
                                        <div className="avatar">
                                            <img src={imgVIP} alt="RarePepeBag" />
                                        </div>
                                        <div className="infor">
                                            <div className="author-name"></div>
                                            <span>Creator</span>
                                        </div>
                                        
                                    </div> */}
                                    {/* <pre>{JSON.stringify(item,null,2)}</pre> */}
                                    <div class="marketplaces">
                                    {selection !== "myNotRarePepes" ? (
                                        <ul>
                                            <li><a target="_blank" rel="noreferrer" href={'https://scarce.city/marketplace/digital/'+(item.title).toUpperCase()}><img key={index} src={imgScarce} alt="Sacrce.city" /></a></li>
                                            <li><a target="_blank" rel="noreferrer" href={'https://dankset.io/assets/'+(item.title).toUpperCase()}><img src={imgDankset} alt="" /></a></li>
                                            
                                            <li><a  target="_blank" rel="noreferrer" href={'https://opensea.io/collection/emblem-vault?search[stringTraits][0][name]=Rare%20Pepe&search[stringTraits][0][values][0]='+(item.title).toUpperCase()}><img src={imgOpensea} alt="" /></a></li>
                                            <li><a  target="_blank" rel="noreferrer" href={'https://pepe.wtf/asset/'+(item.title).toUpperCase()}><img src={imgWTF} alt="pepewtf" /></a></li>
                                           
                                            <li><a  target="_blank" rel="noreferrer" href={'https://xchain.io/asset/'+(item.title).toUpperCase()}><img src={imgXchain} alt="xchain" /></a></li>
     
                                        </ul>
                                         ):(
                                            <ul>
                                            <li><a target="_blank" rel="noreferrer" href={'https://scarce.city/marketplace/digital/'+(item.title).toUpperCase()}><img key={index} src={imgScarce} alt="Sacrce.city" /></a></li>
                                            <li><a target="_blank" rel="noreferrer" href={'https://dankset.io/assets/'+(item.title).toUpperCase()}><img src={imgDankset} alt="dankset" /></a></li>
                                            <li><a  target="_blank" rel="noreferrer" href={'https://xchain.io/asset/'+(item.title).toUpperCase()}><img src={imgXchain} alt="xchain" /></a></li>
                                        </ul>
                                         ) }

                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))
                }
                </Row>
                {
                    visible < data[selection].filter(filtering).length && 
                    <div className="center">
                        <Button id="loadmore" variant="success" className="" onClick={showMoreItems}><span>Explore More</span>
                        </Button>
                    </div>
                }

    </section>
    );
};

export default VaultViewer;
