import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { assetsSelector, comparisonAssetsSelector } from "../redux/selectors";
import { loadAssets, loadComparisonAssets, updateComparisonAssets,reorderComparisonAssets } from "../redux/interactions";
import VaultViewer from "../components/VaultViewer/VaultViewer";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { validate, getAddressInfo } from "bitcoin-address-validation";

import liquidationOffers from "./../assets/data/liquidation-offers.json";
import img1 from "./../assets/images/bag-man/gentelman.gif";

import "./VaultCompare.css";
import { comparisonAssetsLoaded } from "../redux/actions";
const VaultCompare = (props) => {
  const { assets, comparisonAssets, dispatch } = props;
  const [allAssets, setAllAssets] = useState(assets);
  const [comparisonAddress, setComparisonAddress] = useState("");
  const [showOnlyDoubles, setshowOnlyDoubles] = useState(false);
  const [validBitcoinAddress, setValidBitcoinAddress] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [dontShowHoldingOnlyCount, setDontShowHoldingOnlyCount] = useState(1)
  useEffect(() => {
    if (!assets) {
      loadAssets(dispatch);
    }
  });
 const submitHandler =(e)=> {
    e.preventDefault();
}
  const handleChange = (event) => {
    setComparisonAddress(event.target.value);
    var valid = validate(event.target.value);
    if (valid) {
      setValidBitcoinAddress(true);
      loadComparisonAssets(
        dispatch,
        event.target.value,
        assets.notGotRarePepes
      );
    } else {
      setValidBitcoinAddress(false);
    }
  };

  const setSelected = (asset,selected)=> {

    // const storedComparisonAssets = [...comparisonAssets]
  
    // console.log(storedComparisonAssets[storedComparisonAssets.indexOf(asset)] = {...asset, selected});
    
    // dispatch(comparisonAssetsLoaded(storedComparisonAssets[storedComparisonAssets.indexOf(asset)] = {...asset, selected}));
  
    updateComparisonAssets(dispatch, comparisonAssets, asset, selected)  
  }

  const TotalAndCards = (props)=>{
     return <span>{Number(props.total).toLocaleString('en','US')} $bag for {props.cards} Cards.</span>
  }


  const totalOffer = ()=>{
    return {total:comparisonAssets.filter((asset) =>
    showOnlyDoubles ? asset.holding > dontShowHoldingOnlyCount : true
  ).filter(asset=>asset.selected).reduce((runningtotal, nextitem)=>runningtotal+=(
      liquidationOffers.offers.filter(
        (offer) =>
          (nextitem.quantity -nextitem.burned) >= offer.rangeFrom &&
          (nextitem.quantity -nextitem.burned) <= offer.rangeTo
      )[0].offer == null?0:liquidationOffers.offers.filter(
        (offer) =>
          (nextitem.quantity -nextitem.burned) >= offer.rangeFrom &&
          (nextitem.quantity -nextitem.burned) <= offer.rangeTo
      )[0].offer
    ), 0),cards:comparisonAssets.filter((asset) =>
    showOnlyDoubles ? asset.holding > dontShowHoldingOnlyCount : true
  ).filter(asset=>asset.selected).length}
    }
  return (
    <Container className="vault-compare">
      {assets ? (
        <Form onSubmit={submitHandler}>
          <Row>
            <Col md="4">
              <img className="bag-man-img" src={img1} alt="This is Gentlemen" />
            </Col>
            <Col md="8">
              <h1>Compare your Assets against the Rare Pepe Bag Assets</h1>
              <p>
                Supply your bitcoin address and compare your rarepepe cards
                against the rarepepbag vault, Assets you own but the vault
                doesnt own will show. You can also pptionally choose to only view
                'doubles' or multiples you have in your collection.
              </p>
              <p>
                A $bag liquidation price will presented if we have open offers
                for your card, these offers are probably below the floor and are
                intended to be used in groups of cards for anyone who might be looking for
                access to bag tokens and the project via their own rarepepe
                collection.
              </p>
              <p>
                Any donations are of course greatly appreciated and are very
                alpha to provide. Rewards for donations include Rare Pepe
                Starter Packs (a 13 for one deal) and Nakahoneypot cards.
                ('donate card' button coming soon)
              </p>
              <p>Coming Soon: Donate button and 'Offer Builder' where you can choose what cards youd like to liquidate and see a running total of the $bag offer.</p>
              <Form.Group as={Row} className="mb-3" controlId="compareAddress" >
              <Col sm="2">
                <Form.Label>
                  Compare to:{" "}
                </Form.Label>
                </Col>
                <Col sm="10">
                  <Form.Control
                    value={comparisonAddress}
                    onChange={handleChange}
                    type="text"
                    placeholder="Your XCP Address"
                  />
                  {comparisonAddress !== "" &&
                    validBitcoinAddress === false && (
                      <p className="address-validation invalid">
                        Provide a valid bitcoin address
                      </p>
                    )}
                  {validBitcoinAddress && (
                    <>
                      <p className="address-validation success">
                        Valid Bitcoin Address
                      </p>
                    </>
                  )}
                </Col>
              </Form.Group>

              <Form.Group as={Row} className="mb-3">
              <Col sm="2">&nbsp;</Col>
                <Col sm="10">
                  <Form.Check
                    id="checkSwitch"
                    label={!showOnlyDoubles?`Dont show Single Cards`:`holding more than ${dontShowHoldingOnlyCount}`}
                    className="form-check-box"
                    type="switch"
                    onChange={(e) => {
                      console.log(!showOnlyDoubles);
                      setshowOnlyDoubles(!showOnlyDoubles);
                    }}
                  ></Form.Check>
                  { showOnlyDoubles && (
                  <Form.Control
                    value={dontShowHoldingOnlyCount}
                    onChange={(e)=>{setDontShowHoldingOnlyCount(e.target.value)}}
                    type="number"
                    placeholder="1"
                    style={{width:"60px"}}
                  />
                  )}
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-3">
              <Col sm="2">&nbsp;</Col>
                <Col sm="10">
                  <Form.Check
                    id="checkSwitch"
                    label="Table View"
                    className="form-check-box"
                    type="switch"
                    onChange={(e) => {
                      console.log(!tableView);
                      setTableView(!tableView);
                    }}
                  ></Form.Check>
                </Col>
              </Form.Group>
            </Col>
          </Row>

          {validBitcoinAddress && !comparisonAssets && (
            <Row>
              <div className="spinner-wrapper">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">
                    Loading comparison vault contents...
                  </span>
                </Spinner>
              </div>
            </Row>
          )}
          {validBitcoinAddress &&
            comparisonAssets &&
            comparisonAssets.length && (
              <div>
                {!tableView ?(
                  <>
                <Row className="comparison-results">
                  {comparisonAssets
                    .filter((asset) =>
                      showOnlyDoubles ? asset.holding > dontShowHoldingOnlyCount : true
                    )
                    .map((asset) => (
                      <Col className="comparison-card" md="2" onClick={()=>{setSelected(asset, !asset.selected)}}>
                        <img src={asset.img_url} alt={asset.title} />
                        <p>
                          <strong>{asset.title} </strong>
                          <br />
                          You own {asset.holding} of {asset.quantity- asset.burned}
                          <br />
                          {/* {JSON.stringify(liquidationOffers.offers[0],null,2)} */}
                          {liquidationOffers.offers.filter(
                            (offer) =>
                              (asset.quantity -asset.burned) >= offer.rangeFrom &&
                              (asset.quantity -asset.burned) <= offer.rangeTo
                          ).length > 0 && (
                            <span>
                              {liquidationOffers.offers.filter(
                                (offer) =>
                                  (asset.quantity -asset.burned) >= offer.rangeFrom &&
                                  (asset.quantity -asset.burned) <= offer.rangeTo
                              )[0].offer == null ? (
                                <span class="bag-offer offer-na">
                                  $bag offer:{" "}
                                  <span class="bag-offer-note">
                                    {
                                      liquidationOffers.offers.filter(
                                        (offer) =>
                                        (asset.quantity -asset.burned) >= offer.rangeFrom &&
                                        (asset.quantity -asset.burned) <= offer.rangeTo
                                      )[0].note
                                    }
                                  </span>
                                </span>
                              ) : (
                                <span class="bag-offer offer-bag">
                                  $bag offer:{" "}
                                  {liquidationOffers.offers
                                    .filter(
                                      (offer) =>
                                      (asset.quantity -asset.burned) >= offer.rangeFrom &&
                                      (asset.quantity -asset.burned) <= offer.rangeTo
                                    )[0]
                                    .offer.toLocaleString("en", "US")}
                                </span>
                              )}
                              <Form.Check
                             
                              checked={asset.selected}
                              className="form-check-box"
                              type="switch"
                              onChange={(e) => {
                                setSelected(asset, e.target.checked);
                              }}
                            ></Form.Check>
                            </span>
                          )}
                        </p>
                      </Col>
                    ))}
                </Row>
                <div className="totals">
                <TotalAndCards {...totalOffer()} />
                </div>
              </>

):(

<>

                <Row className="comparison-results-table">
                  <table>
                    <tr><th>Image</th><th>Card Name</th><th onClick={()=>{reorderComparisonAssets(dispatch, comparisonAssets, 'holding')}}> holding</th><th onClick={()=>{reorderComparisonAssets(dispatch, comparisonAssets, 'quantity')}}>Active issuance</th><th onClick={()=>{reorderComparisonAssets(dispatch, comparisonAssets, 'card')}}>Series</th><th>Offer</th><th>&nbsp;</th></tr>
                  {comparisonAssets
                    .filter((asset) =>
                      showOnlyDoubles ? asset.holding > dontShowHoldingOnlyCount : true
                    )
                    .map((asset) => (
                      <tr className="comparison-card-table-row" onClick={()=>{setSelected(asset, !asset.selected)}} >
                        <td>
                        <img style={{height:"60px"}} src={asset.img_url} alt={asset.title} />
                        </td>
                        <td>
                          <strong>{asset.title} </strong>
                          </td>
                        <td>
                        {asset.holding}  </td>
                        <td>{asset.quantity- asset.burned}
                          </td>
                        
                        <td>{asset.series}
                          </td>
                        <td>
                         
                          {liquidationOffers.offers.filter(
                            (offer) =>
                              (asset.quantity -asset.burned) >= offer.rangeFrom &&
                              (asset.quantity -asset.burned) <= offer.rangeTo
                          ).length > 0 && (
                            <>
                            <span>
                              {liquidationOffers.offers.filter(
                                (offer) =>
                                  (asset.quantity -asset.burned) >= offer.rangeFrom &&
                                  (asset.quantity -asset.burned) <= offer.rangeTo
                              )[0].offer == null ? (
                                <span class="bag-offer offer-na">
                                  $bag offer:{" "}
                                  <span class="bag-offer-note">
                                    {
                                      liquidationOffers.offers.filter(
                                        (offer) =>
                                        (asset.quantity -asset.burned) >= offer.rangeFrom &&
                                        (asset.quantity -asset.burned) <= offer.rangeTo
                                      )[0].note
                                    }
                                  </span>
                                </span>
                              ) : (
                                <span class="bag-offer offer-bag">
                                  $bag offer:{" "}
                                  {liquidationOffers.offers
                                    .filter(
                                      (offer) =>
                                      (asset.quantity -asset.burned) >= offer.rangeFrom &&
                                      (asset.quantity -asset.burned) <= offer.rangeTo
                                    )[0]
                                    .offer.toLocaleString("en", "US")}
                                </span>
                              )}
                              </span>
                                      
                              
                            </>
                          )}
                          </td>
                          <td>
                          <Form.Check
                             
                             checked={asset.selected}
                             className="form-check-box"
                             type="switch"
                             onChange={(e) => {
                               setSelected(asset, !asset.selected);
                             }}
                           ></Form.Check>
                        </td>
                      </tr>
                    ))}
                    <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td colspan="2" align="right" className="totals">
                    <TotalAndCards {...totalOffer()} />
                      </td>
                    </tr>
                    </table>
                </Row>
                </>
                )}
              </div>
            )}
        </Form>
      ) : (
        <div className="spinner-wrapper">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading Vault Content...</span>
          </Spinner>
        </div>
      )}
    </Container>
  );
};

function mapStateToProps(state) {
  return {
    assets: assetsSelector(state),
    comparisonAssets: comparisonAssetsSelector(state),
  };
}

export default connect(mapStateToProps)(VaultCompare);
