import {get} from 'lodash';
import {createSelector} from 'reselect';

// WEB3
const web3 = state => get(state, 'web3.connection', null);
export const web3Selector = createSelector(web3, w => w);

const account = state => get(state, 'web3.account', null);
export const accountSelector = createSelector(account, a => a);

// OTHER

const assets = state =>get(state, 'app.assets',null);
export const assetsSelector = createSelector(assets,a=>a);

const config = state =>get(state, 'app.config',null);
export const configSelector = createSelector(config,c=>c);

const honeypot = state =>get(state, 'app.honeypot',null);
export const honeypotSelector = createSelector(honeypot,c=>c);


const comparisonAssets = state =>get(state, 'app.comparisonAssets',null);
export const comparisonAssetsSelector = createSelector(comparisonAssets,a=>a);