
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import routes from './pages/router';
import {connect} from 'react-redux';
import './App.css';
import { configSelector } from "./redux/selectors";
import Header from "./components/Header";
import Footer from "./components/Footer";
window.Buffer = window.Buffer || require("buffer").Buffer;


function App() {

  return (

      <Router>
       
        <Header />
          <Routes>
            {
            routes.map((data,index) => (
             
                <Route exact={true} path={data.path} element={data.component} key={index} />
       
            ))
            }
        </Routes>
            <Footer/>
      </Router>
  );
}

function mapStateToProps(state){
	return {
    config:configSelector(state),
	}
}

export default connect(mapStateToProps)(App);
