import { useEffect, useState } from "react";
import { recoverMessageAddress } from "viem";
import { useSignMessage } from "wagmi";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './MessageSigner.scss'
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default function MessageSigner() {
  const [recoveredAddress, setRecoveredAddress] = useState("");
  const [message, setMessage] = useState();
  const { data: signature, variables, error, isLoading, signMessage } = useSignMessage();
  const [copied, setCopied] = useState("");

  useEffect(() => {
    (async () => {
      if (variables?.message && signature) {
        const recoveredAddress = await recoverMessageAddress({
          message: variables?.message,
          signature,
        });
        setRecoveredAddress(recoveredAddress);
      }
    })();
  }, [signature, variables?.message]);

  const didCopy = async()=>{
    setCopied('');
    setTimeout(() => {
      setCopied('copied to clipboard');
      setTimeout(() => {
        setCopied('');
      }, 3000);
    }, 200);
  }

  return (
    <section className="address-signing-tool">
    <h1>Address Signing</h1>
    
    <p>Use this tool to generate signed messages from your address.</p>
    <p><a href="https://etherscan.io/verifiedSignatures#" rel="noreferrer" target="_blank">Etherscan</a> also has a UI for wallet signing.</p>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
         ;
         
          signMessage({ message });
        }}
      >
        <Row>
          <Col md="9">
            <Form.Control name="message" value={message} onChange={(e)=>{setMessage(e.target.value)}} type="text" required />
          </Col>
          <Col md="3">
            <Button disabled={isLoading} type="submit">
          {isLoading ? "Check Wallet to continue" : "Sign Message"}
        </Button>
          </Col>
        </Row>
        
        
      </Form>

      {signature && (
        <div>
          <h4>Generated Signature:</h4>
          <p>
            {signature}
          </p>
          <textarea className="signed-message" value={JSON.stringify({"address":recoveredAddress,"msg":message,"sig":signature,"version":String(2)},null,2)}>
          </textarea>
          <CopyToClipboard onCopy={()=>{didCopy()}} text={JSON.stringify({"address":recoveredAddress,"msg":message,"sig":signature,"version":String(2)},null,2)}>
          
       
           
          <Button className="btn-success" >Copy to clipboard</Button>
         
       </CopyToClipboard>
       {copied && (
        <p className="copied-confirmation">
        {copied}
        </p>
       )}
       
        </div>
      )}
      {error && <div>Error: {error?.message}</div>}
      <p className="verify-signatures">
        You can verify signatures by using services such as <a target="_blank" rel="noreferrer" href="https://app.mycrypto.com/verify-message">My crypto</a>, <a target="_blank" rel="noreferrer" href="https://etherscan.io/verifiedSignatures">Etherscan</a> or via code.
      </p>
    </section>
  );
}