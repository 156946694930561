import React from "react";

import imgS1 from './../../assets/images/slides/1.jpg'
import imgS2 from './../../assets/images/slides/2.png'
import imgS3 from './../../assets/images/slides/3.png'
import imgS4 from './../../assets/images/slides/4.png'
import imgS5 from './../../assets/images/slides/5.png'
import './RarepepeEducation.css';
import EducationSlider from "./EducationSlider";
const martinLink = "<br/><br/><a href=\"https://mlo.art/news/blog-posts/a-brief-history-of-rare-pepes/\" target=\"_blank\" className=\" credit sc-button btn-success\">Some amended extracts from <i>Martin Lukas Ostachowski</i>'s article please take time to read the article in full. </a>";
const dataSlider = [
  {
    title:"RarePepes Today",
    description:'As historically relevant and culturally rich digital artefacts on the bitcoin blockchain, Rare Pepe Trading cards have been collected and traded (decentrally via XCP DEX, and also OTC deals and private Auctions) for over 5 years. These markets and collectors have been active since the projects inception and prices have slowly rised to reflect how specially these items are regarded.<br/><br/> These memetically dense cards that often snapshot historical blockchain events whilst also roasting and toasting some of the worlds most well known celebrities and famous bitcoin badgers and characters and have earned a special place in the heart and digital wallets of the worlds most renowned NFTs collectors and Artists alike.<br/><br/> Collecting is a sport to some, with pepe.wtf leaderboards moving constantly and competition is tough if you want to climb to page 1.<br/><br/>Rare \'Rare Pepe\' trading cards exchange hands for high values, and often - with auction houses seeing bids of 3 million USD on select pieces (PEPENOPOLOUS) and series 1 card 1 \'The NAKAMOTO Card\' card floor at well over 100ETH.',
    storyImage:imgS1,

    
  },
    {
      title:"RarePepes History pt.1",
      description:'Rare Pepes are collectible digital trading cards that memetically explore the cartoon frog, Pepe, to varying degrees. American artist Matt Furie first created the character Pepe back in 2005. Matt scanned the comics and posted them on his Myspace account, where they went viral. The Rare Pepe project, meanwhile, evolved from Pepe\’s meteoric rise as the internet\’s (and moreover crypto\'s) mascot, peaking in 2014-2015 when celebrities such as Katy Perry and Nicki Minaj posted viral Pepe memes on their social media channels. '+martinLink,
      storyImage:imgS1,
  
      
    },
    {
      title:"RarePepes History pt.2",
      description:'In September 2016, an anonymous user called Mike created three Pepe trading cards, backing them on the Counterparty protocol, an application layer of the Bitcoin blockchain. Mike tokenized the trading cards as Counterparty assets in indistinguishable editions. Although they are conceptually often referred to as some of the first NFTs, they are, by definition, not NFTs due to their fungibility. (like ERC1155 than ERC721 standard) '+martinLink,
      storyImage:imgS2,
    },
    {
      title:"What are RarePepes pt.3",
      description:'Mike\’s project was highly influential in the development of the crypto art movement. Rare Pepes became an inclusive community-driven project when Joe Looney adapted his BTCPAY Counterparty marketplace to create the Rare Pepe Wallet, a wallet and essential marketplace to collect, display and trade the trading cards dedicated to Rare Pepes. Until then, only a few technologists and even fewer Blockchain gaming companies were exploring the idea of tokenizing assets.'+martinLink,
      storyImage:imgS3,
      
    }, {
      title:"RarePepes History pt.4",
      description:'With Rare Pepes, anyone who demonstrated a minimum of effort and respected the ‘safe for work’ policy could submit designs to create Rare Pepe cards. In doing so, Rare Pepes removed the technical entry barriers to creating crypto art and initiated hundreds of users and first-time collectors. Hundreds of cards were made, shifting the focus of the meme towards documenting its Blockchain personalities and history.'+martinLink,
      storyImage:imgS4,
      
    }, {
      title:"RarePepes History pt.5",
      description:'Rare Pepe cards gained more attention and eventually caught a milestone event in New York on January of 2018,  The “Homer Pepe” card by the anonymous artist ICQ sold for the record-breaking sum of USD 39,000 in the internal 350,000 PepeCash digital currency derived from a Rare Pepe card of high issuance. The auction made international news and put the concept of tokenizing artworks and firmly placing NFTs on the map. These events have long past and more recently 1 of 1 RarePepes card have reached 3m USD and the 298 RAREPEPEs (Card 1 Series 1) currently have a floor price of around 7 BTC'+martinLink,
      storyImage:imgS5,
      
    }];

const RarePepesEducation = (props) => {
  return (
    <section className="education-details">
        <EducationSlider data={dataSlider} />
    </section>
  );
};

export default RarePepesEducation;
