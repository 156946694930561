import React from "react";

import RarepepeEducation from "./../components/Home/RarepepeEducation";

const RarePepe = (props)=> {
    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            
            <RarepepeEducation />
          </div>
        </div>
      </div>
    )
}



export default RarePepe;