import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './redux/configure';
import { Provider } from 'react-redux';

import {
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  safeWallet
} from '@rainbow-me/rainbowkit/wallets';

import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultWallets, RainbowKitProvider, darkTheme,connectorsForWallets } from '@rainbow-me/rainbowkit';
import { configureChains, createConfig, WagmiConfig } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
  zora,
  goerli,
} from 'wagmi/chains';
import { publicProvider } from 'wagmi/providers/public';

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [
    mainnet,
    polygon,
    optimism,
    arbitrum,
    base,
    zora,
    ...(process.env.REACT_APP_ENABLE_TESTNETS === 'true' ? [goerli] : []),
  ],
  [publicProvider()]
);

const { connectors } = getDefaultWallets({
  appName: 'RarePepeBag - BagHolder ERC20',
  projectId: 'c4059916fa8e43a3919ad5e6f56a7ffe',
  chains,
});

// const connectors = connectorsForWallets(
//   [
//     {
//       groupName: 'Suggested',
//       wallets: [
//         rainbowWallet,
//         metaMaskWallet,
//         coinbaseWallet,
//         walletConnectWallet,
//         // safeWallet
//       ],
//     },
//   ],
//   { appName: 'RarePepeBag - BagHolder ERC20', projectId: 'c4059916fa8e43a3919ad5e6f56a7ffe' },
// );


const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
});



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <Provider store={configureStore()}>
      <React.StrictMode>
        <WagmiConfig config={wagmiConfig}>
          <RainbowKitProvider chains={chains} 
          theme={darkTheme({
      accentColor: '#198754',
      accentColorForeground: 'white',
      borderRadius: 'medium',
      fontStack: 'system',
      overlayBlur: 'small',
    })}>
            <App />
          </RainbowKitProvider>
          </WagmiConfig>
      </React.StrictMode>
    </Provider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
