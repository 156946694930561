import {combineReducers} from 'redux';

function web3(state = {}, action) {
  switch (action.type) {
    case 'WEB3_LOADED':
      return { ...state, connection: action.connection };
    case 'ACCOUNT_LOADED':
      return { ...state, account: action.account};
    default:
      return state;
  }
}

function app(state = {}, action) {
  switch (action.type) {
    case 'CONFIG_LOADED':
      return { ...state, config: action.config };
    case 'ASSETS_LOADED':
        return { ...state, assets: action.assets };
    case 'HONEYPOT_LOADED':
      return { ...state, honeypot: action.honeypot };
      case 'COMPARISON_ASSETS_LOADED':
        return { ...state, comparisonAssets: action.comparisonAssets };
      case 'COMPARISON_ASSETS_UPDATED':
        return { ...state, comparisonAssets: action.comparisonAssets };

    default:
      return state;
  }
}

const rootReducer = new combineReducers({
  web3, app
});

export default rootReducer;
