import react from 'react'
import './HoneyPot.css';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HoloCard from "../HoloCard/HoloCard";
import img1 from "./../../assets/images/img-collection/RAREPEPE.jpg";



const Honeypot = ()=>{
    return (
        <section className="nakahoneypot nakahoneypot-honeypot rounded text-body-emphasis bg-body-secondary">
        <Row>
          <Col>
            {/* <pre>{JSON.parse(coinData,null,2)}</pre> */}
            <h1 className="fst-italic">What is The Naka HoneyPot?</h1> 
            <p>The NakaHoneyPot is the communal effort to build a trade for a RAREPEPE (S1 C1) Card, It collects RAREPEPE cards, other assets, XCP and Bitcoin.</p>
            <p>The community contributes via card or asset donations, or via card purchases (1st card coming soon, and more cards to come from original rare pepe artists) these cards will only be available to contributors/donators.</p>
            <p>Alpha donations from: @indelible, of over 10k value</p>
            <p>Honourable mentions to our early donators (names to come soon.)  </p>
            <p>Look below to see what the naka honey pot currently holds, or to find out more about our digital collectibles.</p>
            
          </Col>
          <Col>
            <HoloCard
              image={img1}
              alt="THE HOLY CRYPTO GRAIL"
              title="THE HOLY CRYPTO GRAIL"
            />
            <p>
              <strong>RAREPEPE</strong> Series 1, Card 1 <br /> A side-quest for
              the rarepepebag is to acquire its own NAKAMOTO card, a true holy grail of 
              historic NFTs. The NAKAHONEYPOT has been started as communal effort to build a tempting trade up for a nakamoto card.
            </p>
          </Col>
        </Row>
      </section>
    )
}

export default Honeypot