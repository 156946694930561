const menus = [
    {
        id: 1,
        name: 'Home',
        namesub: [
        ],
        links: '/'
    },
    {
        id: 3,
        name: 'RarePepes',
        namesub: [
        ],
        links: '/rarepepe'
    },
    {
        id: 4,
        name: 'NAKA HoneyPot',
        namesub: [
        ],
        links: '/honeypot'
    },
    {
        id: 2,
        name: 'Vault',
        namesub: [
        ],
        links: '/vault'
    }  ,
    {
        id: 5,
        name: 'Your $bag',
        namesub: [
        ],
        links: '/bag-token'
    }    

]

export default menus;
