export function assetsLoaded(assets){
  return {
    type: 'ASSETS_LOADED',
    assets
  }
}

export function comparisonAssetsLoaded(comparisonAssets){
  return {
    type: 'COMPARISON_ASSETS_LOADED',
    comparisonAssets
  }
}

export function honeypotLoaded(honeypot){
  return {
    type: 'HONEYPOT_LOADED',
    honeypot
  }
}

export function configfLoaded(config){
    return {
      type: 'CONFIG_LOADED',
      config
    }
  }

export function comparisonAssetsUpdated(comparisonAssets){
  return {
    type: 'COMPARISON_ASSETS_UPDATED',
    comparisonAssets
  }
}