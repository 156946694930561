import React from 'react';
import { Link } from 'react-router-dom'
import { Navigation, Pagination, Scrollbar, A11y   } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import 'swiper/css';
import CardFan from './CardFan';

const Slider = props => {
    const data = props.data
    return  (
        <section className="tf-slider slider">
            <Swiper
                modules={[Scrollbar, A11y ]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    scrollbar={{ draggable: true }}
                >
                {
                    data.map((item, index) => (
                        <SwiperSlide key={index}>
                            <SliderItem item={item} />
                        </SwiperSlide>

                    ))
                }
            </Swiper>
        </section>
    )
};

const htmlDecode = content => {
    let e = document.createElement("div");
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  };

const SliderItem = props => (
    <div className="swiper-container ">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slider-item">
                    <div className="overlay"></div>
                    <div className='container'>
                    <div className="slider-inner flex home-1 style-2">
                        <Row>
                            <Col sm="12" md="6" lg="6">
                        <div className="slider-content">
                            <h1 className="heading">{props.item.title}</h1>
                            <p className="sub-heading" dangerouslySetInnerHTML={{ __html: props.item.p3 }}></p>
                            {/* <p className="sub-heading">
                            The liquidity pool from the <a href="https://fractional.art/vaults/bagholder" target="_blank">$bagholder vault</a> collects fees, those fees will be used to further accrue rarepepes for this vault to own. any trading will enduce fee collection meaning chart progress in either directions helps to form more value into the underlying asset (the collection).
                            </p> */}
                            <p className="sub-heading"  dangerouslySetInnerHTML={{ __html: props.item.description }}></p>
                             <p className="sub-heading" dangerouslySetInnerHTML={{ __html: props.item.p1 }}></p>
                             {/* <p className="sub-heading" dangerouslySetInnerHTML={{ __html: props.item.p2 }}></p> */}
                            {/*<p className="sub-heading">{props.item.p1}</p> */}
                            <div className="button-slider">
                            </div>
                        </div>
                        </Col>
                        <Col  sm="12" md="6" lg="6">
                        <div className="slider-img flex card-fan-container">
                            <CardFan cards={[
                                {"img":props.item.img1,"name":'KANDINSKYPEP'},
                                {"img":props.item.img2,"name":'SHITCOINCARD'},
                                {"img":props.item.img3,"name":'EAZYP'},
                                {"img":props.item.img4,"name":'CULTOFKEK'},
                                {"img":props.item.img5,"name":'PLATINUMPEPE'},
                                {"img":props.item.img6,"name":'CHEECHPEPE'},
                                {"img":props.item.img7,"name":'PEPEGOAT'},
                                {"img":props.item.img9,"name":'GOXPEPE'},
                               
                            ]}></CardFan>
                                    
                            <div className="img-right">
                                {/* <img src={props.item.img5} alt="RarePepeBag" /> */}
                                
                                        
                                <div className="box-avatar flex">
                                    {/* <div className="list-avatar flex">
                                        <img src={props.item.avt1} alt="SuperRarePepes" title="SuperRareRares" />
                                        <img src={props.item.avt2} alt="bettidomas" title="Bettidomas" />
                                        <img src={props.item.avt3} alt="NathanSonic" title="NathanSonic" />
                                        <img src={props.item.avt4} alt="Easy_To_The_B" title="Easy_To_The_B" />
                                        <img src={props.item.avt5} alt="BrianMicon" title="BrianMicon"/>
                                        <img src={props.item.avt6} alt="Carsonated" title="Carsonated" />
                                        <img src={props.item.avt7} alt="SureItsNothing" title="SureItsNothing" />
                                        <img src={props.item.avt8} alt="ScrillaVentura" title="ScrillaVentura" />
                                        <img src={props.item.avt9} alt="WasThataWolf" title="WasThataWolf" />
                                        <img src={props.item.avt10} alt="CryptoChainer" title="CryptoChainer" />
                                        <img src={props.item.avt11} alt="ExmoorBeast" title="ExmoorBeast" />
                                        <img src={props.item.avt12} alt="Emblematix" title="Emblematix" />
                                        <img src={props.item.avt13} alt="ShawnLeary" title="ShawnLeary" />
                                        <img src={props.item.avt14} alt="Jdogresorg" title="Jdogresorg" />
                                        
                                    </div> */}
                                   
                                    <div className="icon-plus">
                                        <Link to="#"><i className="fas fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Col>
                        </Row>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

)

export default Slider;
