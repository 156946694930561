import React from 'react';

import { Navigation, Pagination, Scrollbar, A11y, Autoplay   } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react'

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from 'react-bootstrap/Container';
import './EducationSlider.css';

const Slider01 = props => {
    const data = props.data
    return  (
        <section className="slider">
            <Swiper
                modules={[Navigation,  Scrollbar, Autoplay, A11y ]}
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation={true}
                    autoplay={{delay:12000}}
                    speed={300}
                    length
                    scrollbar={{ draggable: true }}
                >
                {
                    data.map((item, index) => (
                        <SwiperSlide key={index}>
                            <SliderItem item={item} />
                        </SwiperSlide>

                    ))
                }
            </Swiper>
        </section>
    )
};

const SliderItem = props => (
    <Container>
    <Row>
        <Col sm="12" md="6" lg="9">
            <h1 className="heading">{props.item.title}</h1>
            <p className="sub-heading" dangerouslySetInnerHTML={{ __html:props.item.description}}></p>
            
        </Col>
        <Col  sm="12" md="6" lg="3">
        <img className="slider-image" src={props.item.storyImage}
                alt="RarePepeBag" />
        <p className="sub-heading">Original Pepe Art by <a href="https://twitter.com/sureitsnothing" target="_blank">WhoIsIt</a></p>
        </Col>
    </Row>
    </Container>

)

export default Slider01;
