import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import img12 from "./../../assets/images/img-collection/BAGHOLDRPEPE.jpeg";
import img1 from "./../../assets/images/img-collection/RAREPEPE.jpg";
import HoloCard from "../HoloCard/HoloCard";
import "./Explainer.css";
import { Link } from "react-router-dom";

const contirbutors = [
  {
    name: "Indelible",
  },
  {
    name: "Shawn Leary",
  },
  {
    name: "CryptoChainer",
  },
  {
    name: "Joe Looney",
  },
  {
    name: "Bryan Micon",
  },
  {
    name: "Rare Scrilla",
  },
  {
    name: "Mr. Hansel",
  },
  {
    name: "Eazy B",
  },

  {
    name: "Nathan Sonic",
  },
  {
    name: "WhoIsIt",
  },
  {
    name: "Carsonated",
  },
  {
    name: "Emblematix",
  },
];
const Explainer = (props) => {
  return (
    <section className="explainer rounded text-body-emphasis bg-body-secondary">
      <Row>
        <Col>
          {/* <pre>{JSON.parse(coinData,null,2)}</pre> */}
          <h1 className="fst-italic">What is RarePepeBag?</h1> 

          <p className="desc top-text"></p>
          <p className="desc top-text">
            Bagholder or{" "}
            <a
              href="https://etherscan.io/address/0x70881d5c8a5950ceedf1f1b4b5d4105718642548"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              $BAG is an ERC20 token
            </a>{" "}
             with 100,000,000 supply representing a vaulted bitcoin wallet
            containing a collection of the historical RAREPEPEs Digital
            collectibles.
          </p>

          
          <p className="desc top-text">
            A{" "}
            <a
              href="https://github.com/fractional-company/contracts/blob/master/src/ERC721TokenVault.sol"
              rel="noreferrer"
              target="_blank"
            >
              fractional.art contract
            </a>{" "}
            has been used to facilitate the fractionalisation of the
            vault meaning the erc20 tokens <strong>are</strong> the
            ownership over the vault.
         
            The contract address is: <a
              href="https://etherscan.io/address/0x70881d5c8a5950ceedf1f1b4b5d4105718642548"
              rel="noreferrer"
              target="_blank"
            >0x70881d5c8a5950ceedf1f1b4b5d4105718642548</a>
            and you can see the uniswap pool trading on{" "}
            <a
              href="https://dexscreener.com/ethereum/0x373cc766e151688265ce9f79cc3d752886ed5c54"
              target="_blank"
              rel="noreferrer"
            >
              dexscreener
            </a>{" "}
            or{" "}
            <a
              href="https://www.dextools.io/app/en/ether/pair-explorer/0x373cc766e151688265ce9f79cc3d752886ed5c54"
              target="_blank"
              rel="noreferrer"
            >
              dextools
            </a>
            .
          </p>

          <p className="desc top-text">
            You can swap ETH for $BAG via{" "}
            <a href="https://app.uniswap.org" target="_blank" rel="noreferrer">
              {" "}
              uniswap app</a>. {/* or <a href="/swap">on site</a>. */}
         
            The liquidity pool for $BAG tokens collects fees on trades, which
            are collected and used to facilitate further expanding the
            RAREPEPEs collection held with in the vault.

            You can verify the{" "}
            
              vault itself at <a
              href="https://emblem.finance/nft?id=1628481"
              rel="noreferrer"
              target="_blank"
            >
              {" "}emblem.finance{" "}
            </a>{" "}
            or the{" "}
            <a
              href="https://xchain.io/address/1KdX4ePLx8hmXcNvz2tD1uKgp5cHMyFDB2"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              bitcoin wallet{" "}
            </a>{" "}
            it controls.
          </p>
          

          <p>The Original contributors to the RarePepeBag include {contirbutors.map((contributor)=>contributor.name).join(', ')}. </p>
          
          
          
          <p className="desc top-text">
            Hit{" "}
            <a href="https://t.me/rarepepebag" target="_blank" rel="noreferrer">
              the Telegram
            </a>{" "}
            or{" "}
            <a
              href="https://discord.gg/KByDnFfT"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              the Discord
            </a>{" "}
            follow the{" "}
            <a
              href="https://twitter.com/rarepepebag"
              target="_blank"
              rel="noreferrer"
            >
              the Twitter
            </a>{" "}
            and join the fam.
          </p>
          
        </Col>
        <Col>
          <HoloCard
            image={img1}
            alt="The Pepe Holy Grail, RAREPEPE or the Nakamoto Card"
          />
          <p>
            <strong>RAREPEPE</strong> Series 1, Card 1 <br /> A side-quest for
            the rarepepebag is to acquire its own NAKAMOTO card, a true holy grail of 
            historic NFTs. <Link
              to="/honeypot"
            
            >The NAKAHONEYPOT</Link> has been started as communal effort to build a tempting trade up for a nakamoto card.
          </p>
        </Col>
      </Row>
    </section>
  );
};

export default Explainer;
