import react from 'react'
import './CardFan.scss';
const CardFan = (props)=>{
    const {cards} = props;
    return (
        <div className="cards-wrap">
            <div className="cards">
            
            {
                cards.map((card,index)=>
                    <div className="card" key={index} style={{ backgroundImage: `url(${card.img})` }}>
                        
                    </div>
                )
            }
            </div>
        </div>
    )
}

export default CardFan