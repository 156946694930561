import React from 'react'

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import HoloCard from "../HoloCard/HoloCard";
import imgXchain from './../../assets/images/markets/xchain.png'
import './Collectible.css'

import QRCode from "react-qr-code";


const Collectible = (props)=>{
    const {collectible} = props;
    return (
        <div className="nakahoneypot nakahoneypot-honeypot_collectible">
            <Container>
            <Row>
                <Col>
                
                    <img src={collectible.asset_img} alt="collectible" />
                    
    
                </Col>
                <Col>
                
                    <img src={collectible.holo_asset_img} alt="holo_collectible" />
                </Col>
                <Col>
                <Row>
                    <h1>{collectible.asset}</h1>
                    <p>Artist:{collectible.artist}</p>
                    <p>Total {collectible.total_supply}, {((collectible.holo_supply/collectible.total_supply)*100).toFixed(2)}% HOLO </p>
                </Row><Row className="asset-row">    
                <h2>Original</h2>
                    <p>Issuance: {collectible.supply}, Price: only {collectible.price} (${collectible.dollar_estimate})</p>
                    <div className="qr-code">
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={`bitcoin:${collectible.dispenser_receiver}?amount=${collectible.price}`}
                            viewBox={`0 0 256 256`}
                            title={`bitcoin:${collectible.dispenser_receiver}?amount=${collectible.price}`}
                            alt={`bitcoin:${collectible.dispenser_receiver}?amount=${collectible.price}`}
                        />
                    </div>
                    <a className="xchain-link" title="dispensoor link" target="_blank" rel="noreferrer" href={collectible.dispenser}><img src={imgXchain} alt="xchain" /></a><br/>
                    <p><small><a className="xchain-link-text" title="dispensoor link" target="_blank" rel="noreferrer" href={collectible.dispenser}>Buy it now on dispenser via the xchain link</a> or by scanning the qr with your bitcoin wallet.</small></p>
                </Row>
                <Row  className="asset-row">
                    <h2>{collectible.holo_asset}</h2>
                    <p>Issuance: {collectible.holo_supply}, Price: only {collectible.holo_price} (${collectible.holo_dollar_estimate})</p>
                    { collectible.holo_dispenser_receiver !== "null" ? ( 
                    <>
                        <div className="qr-code">
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={`bitcoin:${collectible.holo_dispenser_receiver}?amount=${collectible.holo_price}`}
                                viewBox={`0 0 256 256`}
                                alt={`bitcoin:${collectible.holo_dispenser_receiver}?amount=${collectible.holo_price}`}
                                title={`bitcoin:${collectible.holo_dispenser_receiver}?amount=${collectible.holo_price}`}
                            />
                        </div>
                        <a className="xchain-link" title="dispensoor link" target="_blank" rel="noreferrer" href={collectible.holo_dispenser}><img src={imgXchain} alt="xchain" /></a>
                    </>
                    ):(
                        <p>Asset available to dispense soon.</p>
                    )}
                    </Row>
            </Col>
        </Row>
    </Container>
      </div>
    )
}

export default Collectible