import { ConnectButton } from '@rainbow-me/rainbowkit';
import './ConnectHelper.css';

const ConnectHeler = (props) => {
    return (
        <section className="connect-helper">
            <center>
            <p>
                You need to connect to see your balance & history.
            </p>
            <ConnectButton className='connect-button' style={{'justify-content':'space-around'}} />
            </center>
        </section>
    );
};


export default ConnectHeler;
