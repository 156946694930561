import React,{ useEffect } from "react";
import { connect } from "react-redux";
import { assetsSelector } from "../redux/selectors";
import { loadAssets } from "../redux/interactions"
import VaultViewer from "../components/VaultViewer/VaultViewer";
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

const Vault = (props)=> {
    const {assets, dispatch} = props;
    useEffect(()=>{
        if(!assets){
          loadAssets(dispatch);
        }
      })

    return (
        <Container>
            { assets ? (
                <VaultViewer data={assets}/>
            ):(
              <div className="spin-wrapper">
              
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading Vault Content...</span>
              </Spinner>
              
              </div>
            )}
        </Container>
    )
}

function mapStateToProps(state){
	return {
        assets: assetsSelector(state)
	}
}

export default connect(mapStateToProps)(Vault);