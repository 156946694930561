import React,{ useEffect } from "react";
import { connect } from "react-redux";
import { assetsSelector } from "../redux/selectors";
import { loadAssets } from "../redux/interactions"
import Explainer from "../components/Home/Explainer";
import VaultDetails from "../components/Home/VaultDetails";

const Home = (props)=> {
    const {assets, dispatch} = props;
    useEffect(()=>{

        if(!assets){
          console.log("loading assets")
          loadAssets(dispatch);
        }
  
      })

    return (
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <Explainer/>
            <VaultDetails />
          </div>
        </div>
      </div>
    )
}

function mapStateToProps(state){
	return {
    assets: assetsSelector(state)
	}
}

export default connect(mapStateToProps)(Home);